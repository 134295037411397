export const Schema = () => {
  return {
    category: 'Embed',
    label: 'Gorgias Help Center',
    key: 'gorgias-help-center',
    fields: [
      {
        label: 'Gorgias Help Center UID',
        name: 'gorgiasHelpCenterUid',
        component: 'text',
        defaultValue: 'fmlw3kdy',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Section Bottom Spacing',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
};
