import { useInView } from 'react-intersection-observer';
import { ColorVariantOptions } from './ColorVariantOptions';

export function ColorVariantSelector({
  enabledColorNameOnHover,
  initialProduct,
  selectedVariant,
  setProductFromColor,
  setVariantFromColor,
  swatchesMap,
}) {
  const { ref, inView } = useInView({
    rootMargin: '1200px',
    triggerOnce: true,
  });
  const hasMultipleColorsFromProduct =
    !initialProduct?.grouping?.isTransformed &&
    initialProduct?.optionsMap?.Color?.length > 1;
  const hasMultipleColorsFromGrouping =
    initialProduct?.grouping?.isTransformed &&
    initialProduct.grouping.optionsMap.Color?.length > 1;
  const hasMultipleColors =
    hasMultipleColorsFromProduct || hasMultipleColorsFromGrouping;

  return hasMultipleColors && selectedVariant ? (
    <div className="mt-3" ref={ref}>
      {inView && (
        <ColorVariantOptions
          enabledColorNameOnHover={enabledColorNameOnHover}
          initialProduct={initialProduct}
          selectedVariant={selectedVariant}
          setProductFromColor={setProductFromColor}
          setVariantFromColor={setVariantFromColor}
          swatchesMap={swatchesMap}
        />
      )}
    </div>
  ) : null;
}

ColorVariantSelector.displayName = 'ColorVariantSelector';
