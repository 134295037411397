export const Schema = () => {
  return {
    category: 'Embed',
    label: 'Gorgias Contact Form',
    key: 'gorgias-contact-form',
    fields: [
      {
        label: 'Gorgias Form Id',
        name: 'gorgiasFormId',
        component: 'text',
        defaultValue: 'vcqxwrxz',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Section Bottom Spacing',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
};
