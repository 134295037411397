import { useState } from 'react';

import { CollectionFilterOption } from './CollectionFilterOption';
import { Svg } from '../../Svg';

export function CollectionDropdownFilter({
  activeFilters,
  addFilter,
  defaultOpen = false,
  filter,
  removeFilter,
  showCount,
  swatchesMap,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="py-4 md:py-5">
      <button
        aria-label={filter.label}
        className="relative flex w-full items-center justify-between gap-4 px-4 py-1 text-left md:px-0"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <h3 className="m-0 text-sh-upper font-bold uppercase">
          {filter.label}
        </h3>

        <Svg
          className={`text-current w-5 ${isOpen ? 'rotate-180' : ''}`}
          src="/svgs/chevron-down-wide.svg#chevron-down-wide"
          title={`Chevron ${isOpen ? 'Up' : 'Down'}`}
          viewBox="0 0 20 8"
        />
      </button>

      {isOpen && (
        <ul
          className={`md:scrollbar m-0 mt-4 list-none flex-col gap-2 px-4 md:mb-1 md:mr-[8.5px] md:max-h-[11.5rem] md:items-start md:p-0 ${
            isOpen ? 'flex' : 'hidden'
          }`}
        >
          {filter.values.map(({ count, value }, index) => {
            return (
              <li key={index} className="m-0">
                <CollectionFilterOption
                  activeFilters={activeFilters}
                  addFilter={addFilter}
                  count={count}
                  isColor={filter.isColor}
                  name={filter.name}
                  removeFilter={removeFilter}
                  showCount={showCount}
                  swatchesMap={swatchesMap}
                  value={value}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

CollectionDropdownFilter.displayName = 'CollectionDropdownFilter';
