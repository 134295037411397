import { useCallback, useMemo, useState, useEffect } from 'react';
import {
  useProductByHandle,
  useProductInventoryByHandle,
  useRouter,
} from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { ColorVariantSelector } from './ColorVariantSelector';
import { Link } from '../Link';
import { ProductItemMedia } from './ProductItemMedia/ProductItemMedia';
import { ProductItemPrice } from './ProductItemPrice';
import { ProductStars } from '../ProductStars';

export function ProductItem({
  activeColorFilters,
  colorFilterNameMap,
  enabledColorNameOnHover,
  enabledColorSelector,
  enabledQuickShop,
  enabledStarRating,
  handle,
  index,
  isSearchPage,
  onClick,
  product: passedProduct,
  swatchesMap,
  isRebuyPanel,
  rulesetid,
  setOosProducts,
}) {
  const { ref, inView } = useInView({
    rootMargin: '1200px',
    triggerOnce: true,
  });

  // Only render the ProductItemContent when inView is true
  if (!inView) {
    return <div ref={ref} className="min-h-[26rem]" />;
  }

  return (
    <div ref={ref}>
      {inView && (
        <ProductItemContent
          activeColorFilters={activeColorFilters}
          colorFilterNameMap={colorFilterNameMap}
          enabledColorNameOnHover={enabledColorNameOnHover}
          enabledColorSelector={enabledColorSelector}
          enabledQuickShop={enabledQuickShop}
          enabledStarRating={enabledStarRating}
          handle={handle}
          index={index}
          isSearchPage={isSearchPage}
          onClick={onClick}
          product={passedProduct}
          swatchesMap={swatchesMap}
          isRebuyPanel={isRebuyPanel}
          rulesetid={rulesetid}
          setOosProducts={setOosProducts}
        />
      )}
    </div>
  );
}

function ProductItemContent({
  activeColorFilters,
  colorFilterNameMap,
  enabledColorNameOnHover,
  enabledColorSelector,
  enabledQuickShop,
  enabledStarRating,
  handle,
  index,
  isSearchPage,
  onClick,
  product: passedProduct,
  swatchesMap,
  isRebuyPanel,
  rulesetid,
  setOosProducts,
}) {
  const { product: fetchedFullProduct } = useProductByHandle({
    handle,
    fetchOnMount: true,
  });
  const initialProduct =
    fetchedFullProduct || (passedProduct?.loaded ? passedProduct : null);

  const [productFromColor, setProductFromColor] = useState(null);
  const [variantFromColor, setVariantFromColor] = useState(null);
  const router = useRouter();

  const selectedProduct = productFromColor || initialProduct;
  const selectedVariantByTag = useMemo(() => {
    if (
      !selectedProduct ||
      router?.route !== '/collections/[handle]' ||
      (router?.route !== '/collections/[handle]' && !isSearchPage)
    )
      return null;
    const value = selectedProduct?.tags?.find((tag) => {
      return (
        tag.startsWith('selectedVariant::') &&
        tag.split('selectedVariant::').length === 2
      );
    });

    if (!value) return null;
    const selectedVariantID = value.split('selectedVariant::')[1]?.trim();
    if (selectedVariantID) {
      return selectedProduct?.variants?.find(
        (v) => v.legacyResourceId === selectedVariantID
      );
    }
    return selectedProduct?.variants?.[0];
  }, [selectedProduct?.id]);

  const selectedVariant = useMemo(() => {
    if (!selectedProduct) return null;
    const firstVariantMatchingColorFilters =
      activeColorFilters && !variantFromColor
        ? selectedProduct.variants?.find((variant) => {
            return activeColorFilters.some((name) => {
              const colorFilter = name.toLowerCase();
              const selectedColor =
                variant.selectedOptionsMap?.Color?.toLowerCase();
              return (
                selectedColor === colorFilter ||
                colorFilterNameMap?.[colorFilter]?.[selectedColor]
              );
            });
          })
        : null;
    return (
      variantFromColor ||
      firstVariantMatchingColorFilters ||
      selectedVariantByTag ||
      selectedProduct?.variants?.[0]
    );
  }, [
    activeColorFilters,
    colorFilterNameMap,
    variantFromColor?.id,
    selectedProduct?.id,
  ]);

  const productUrl = selectedProduct
    ? `/products/${selectedProduct.handle}${
        selectedVariant?.legacyResourceId
          ? `?variant=${selectedVariant.legacyResourceId}`
          : ''
      }`
    : '';
  const title = selectedProduct?.title;
  const tags = selectedProduct?.tags || [];
  const useValueByTag = (startText) => {
    return useMemo(() => {
      const value = tags.find((tag) => {
        return tag.startsWith(startText) && tag.split(startText).length === 2;
      });

      if (!value) return null;
      return value.split(startText)[1]?.trim();
    }, [tags, startText]);
  };

  const promoText = useValueByTag('promo::');
  const promoText2 = useValueByTag('promo2::');

  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: handle || 'null',
    withInventory: false,
  });

  const handleClick = useCallback(
    (evt) => {
      if (isRebuyPanel) {
        evt.preventDefault();
        if (/\?/.test(productUrl)) {
          router.push(`${productUrl}&_source=rebuy&rulesetid=${rulesetid}`);
        } else {
          router.push(`${productUrl}?_source=rebuy&rulesetid=${rulesetid}`);
        }
      }
      PubSub.publish(
        isSearchPage ? 'CLICK_SEARCH_ITEM' : 'CLICK_COLLECTION_ITEM',
        {
          ...selectedVariant,
          image: selectedProduct?.images?.[0],
          index,
        }
      );
      if (typeof onClick === 'function') onClick();
    },
    [index, selectedProduct?.id, selectedVariant?.id, isRebuyPanel]
  );

  useEffect(() => {
    if (
      selectedProduct &&
      !inventory?.availableForSale &&
      (inventoryStatus?.success || inventoryStatus?.finished) &&
      typeof setOosProducts === 'function'
    ) {
      setOosProducts((prev) => [...prev, selectedProduct.id]);
    }
  }, [index, selectedProduct, inventory]);

  return (
    <div
      className={`group flex h-full flex-col justify-between ${
        !inventory?.availableForSale &&
        (inventoryStatus?.success || inventoryStatus?.finished)
          ? 'oos'
          : ''
      }`}
    >
      <div className="flex flex-col items-start">
        <Link
          aria-label={title}
          className="w-full text-black no-underline"
          href={productUrl}
          onClick={handleClick}
        >
          <div className="mb-3 bg-grey1">
            <ProductItemMedia
              selectedProduct={selectedProduct}
              selectedVariant={selectedVariant}
              enabledQuickShop={enabledQuickShop}
              enabledColorSelector={enabledColorSelector}
            />
          </div>

          {enabledStarRating && (
            <div className="mb-3">
              <ProductStars
                legacyResourceId={initialProduct?.legacyResourceId}
              />
            </div>
          )}

          {title && (
            <h3 className="text-bold m-0 text-sh-upper-sm uppercase md:text-sh-lg">
              {title}
            </h3>
          )}
        </Link>

        <ProductItemPrice
          selectedVariant={selectedVariant}
          promoText2={promoText2}
          promoText={promoText}
        />

        {enabledColorSelector && (
          <ColorVariantSelector
            enabledColorNameOnHover={enabledColorNameOnHover}
            initialProduct={initialProduct}
            selectedVariant={selectedVariant}
            setProductFromColor={setProductFromColor}
            setVariantFromColor={setVariantFromColor}
            swatchesMap={swatchesMap}
          />
        )}
      </div>
    </div>
  );
}


ProductItem.displayName = 'ProductItem';
ProductItem.propTypes = {
  activeColorFilters: PropTypes.array,
  enabledColorNameOnHover: PropTypes.bool,
  enabledColorSelector: PropTypes.bool,
  enabledQuickShop: PropTypes.bool,
  enabledStarRating: PropTypes.bool,
  handle: PropTypes.string,
  index: PropTypes.number,
  isSearchPage: PropTypes.bool,
  onClick: PropTypes.func,
  swatchesMap: PropTypes.object,
};