import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useProductInventoryByHandle,
  useSettings,
} from '@backpackjs/storefront';

export function Badges({
  selectedVariant = null,
  className = '',
  isDraft, // in customizer, badge added to indicate product is draft
  tags = [],
}) {
  const settings = useSettings();
  const { badgeList } = { ...settings?.product?.badges };

  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });

  const isLoading = !inventoryStatus?.success;
  const productIsSoldOut = !isLoading && !inventory?.availableForSale;

  const tagsDowncase = useMemo(() => {
    return (tags || []).reduce((acc, tag) => {
      return [...acc, tag.toLowerCase()];
    }, []);
  }, [tags]);

  const badge = useMemo(() => {
    if (isDraft) {
      return {
        label: 'Draft',
        tag: 'Draft',
        bgColor: 'var(--white)',
        textColor: 'var(--black)',
        borderColor: 'var(--black)',
      };
    }
    if (productIsSoldOut) {
      return (
        (badgeList || []).find((b) => {
          return b?.tag?.toLowerCase() === 'out of stock';
        }) || {
          label: 'Out of Stock',
          tag: 'Out of Stock',
          bgColor: 'var(--white)',
          textColor: 'var(--black)',
          borderColor: 'var(--black)',
        }
      );
    }
    return (badgeList || []).find((b) => {
      const tagDowncase = b?.tag?.toLowerCase();
      return tagsDowncase.includes(tagDowncase);
    });
  }, [isDraft, productIsSoldOut, badgeList, tagsDowncase]);

  return (
    <div
      className={`text-label flex flex-wrap gap-2 text-[11px] text-sh-sm font-bold xs:gap-2 [&_div]:rounded-none [&_div]:px-2 [&_div]:py-1 ${className}`}
    >
      {!!badge && (
        <div
          className="border"
          style={{
            backgroundColor: badge?.bgColor || 'var(--white)',
            color: badge?.textColor || 'var(--black)',
            borderColor: badge?.borderColor || badge?.bgColor || 'var(--black)',
          }}
        >
          {badge?.label}
        </div>
      )}
    </div>
  );
}

Badges.displayName = 'Badges';
Badges.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};
