import { useEffect, useState } from 'react';
import { Schema } from './GorgiasContactForm.schema';

export function GorgiasContactForm({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { gorgiasFormId, section } = cms;
  const [loaded, setLoaded] = useState(false);
  const [gorgiasInjected, setGorgiasInjected] = useState(false);

  useEffect(() => {
    const t = document.getElementById(
      `gorgias-contact-form-loader-${gorgiasFormId}`
    );
    t && t.parentNode.removeChild(t);
    setLoaded(true);
    return () => {
      setLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && loaded && !gorgiasInjected) {
      const js = window.document.createElement('script');
      js.src = 'https://contact.gorgias.help/api/contact-forms/loader.js?v=3';
      js.type = 'text/javascript';
      js.fetchPriority = 'high';
      js.id = `gorgias-contact-form-loader-${gorgiasFormId}`;
      js.setAttribute('data-gorgias-contact-form-uid', gorgiasFormId);
      js.setAttribute('data-gorgias-loader-contact-form', true);
      const wrapper = document.querySelector(
        `#${moduleID} .gorgias-contact-form-${gorgiasFormId}-wrapper`
      );
      if (wrapper) {
        wrapper.appendChild(js);
        setGorgiasInjected(true);
      }
    }
    return () => {
      const t = document.getElementById(
        `gorgias-contact-form-loader-${gorgiasFormId}`
      );
      t && t.parentNode.removeChild(t);
      setGorgiasInjected(false);
    };
  }, [loaded]);

  return (
    <div id={moduleID} className="md-py-contained md-px-contained">
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>

      {gorgiasFormId && (
        <div className={`gorgias-contact-form-${gorgiasFormId}-wrapper`} />
      )}
    </div>
  );
}

GorgiasContactForm.displayName = 'Gorgias Contact Form';
GorgiasContactForm.Schema = Schema;
