export function Schema() {
  return {
    category: 'Image',
    label: 'Visual Categories',
    key: 'visual-categories',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/visual-categories.png?v=1714066494',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
      },
      {
        label: 'Heading Size (Desktop)',
        name: 'headingSize',
        component: 'number',
        description: 'value in px',
      },
      {
        label: 'Heading Size (Mobile)',
        name: 'headingSizeM',
        component: 'number',
        description: 'value in px',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'markdown',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      {
        label: 'Heading & Description Align',
        name: 'headingAlign',
        component: 'select',
        options: [
          { label: 'Left', value: 'text-left' },
          { label: 'Center', value: 'text-center' },
          { label: 'Right', value: 'text-right' },
        ],
      },
      {
        label: 'Tiles',
        name: 'tiles',
        description: 'Min of 3 tiles, max of 8 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          minItems: 3,
          maxItems: 8,
        },
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
            description:
              'Recommended image size is 320px x 260px (320px x 480px with content overlay)',
          },
          {
            label: 'Image (Mobile)',
            name: 'image_m',
            component: 'image',
            description:
              'Recommended image size is 320px x 260px (330px x 594px with content overlay)',
          },
          {
            label: 'Text Color',
            name: 'textColor',
            component: 'select',
            options: [
              { label: 'Black', value: 'text-black' },
              { label: 'White', value: 'text-white' },
            ],
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'html',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Button Style',
            name: 'BtnStyle',
            component: 'select',
            options: [
              { label: 'Orion Fill', value: 'button button--orion' },
              { label: 'Light Fill', value: 'button button--light' },
              { label: 'Black Fill', value: 'button button--black' },
              { label: 'Dark Outline', value: 'button button--dark-outline' },
              { label: 'Light Outline', value: 'button button--light-outline' },
              { label: 'Focus Link Dark', value: 'focus-link text-sm' },
              {
                label: 'Focus Link Light',
                value: 'focus-link text-sm text-white hover:text-medDesert',
              },
            ],
          },
        ],
        defaultItem: {
          alt: '',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1616/9825/files/E100_20Heather_20side_20compressed_400.33x_691b63de-9e4d-437c-b94a-0415be8ddded.png?v=1703101615',
          },
          image_m: {
            src: 'https://cdn.shopify.com/s/files/1/1616/9825/files/E100_20Heather_20side_20compressed_400.33x_691b63de-9e4d-437c-b94a-0415be8ddded.png?v=1703101615',
          },
          textColor: 'text-black',
          heading: 'Headline',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          link: {
            text: 'Shop Now',
            url: '',
          },
          BtnStyle: 'button button--black',
        },
        defaultValue: [
          {
            alt: '',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1616/9825/files/E100_20Heather_20side_20compressed_400.33x_691b63de-9e4d-437c-b94a-0415be8ddded.png?v=1703101615',
            },
            image_m: {
              src: 'https://cdn.shopify.com/s/files/1/1616/9825/files/E100_20Heather_20side_20compressed_400.33x_691b63de-9e4d-437c-b94a-0415be8ddded.png?v=1703101615',
            },
            textColor: 'text-black',
            heading: 'Headline',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            link: {
              text: 'Shop Now',
              url: '',
            },
            BtnStyle: 'button button--black',
          },
          {
            alt: '',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1616/9825/files/E100_20Heather_20side_20compressed_400.33x_691b63de-9e4d-437c-b94a-0415be8ddded.png?v=1703101615',
            },
            image_m: {
              src: 'https://cdn.shopify.com/s/files/1/1616/9825/files/E100_20Heather_20side_20compressed_400.33x_691b63de-9e4d-437c-b94a-0415be8ddded.png?v=1703101615',
            },
            textColor: 'text-black',
            heading: 'Headline',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            link: {
              text: 'Shop Now',
              url: '',
            },
            BtnStyle: 'button button--black',
          },
          {
            alt: '',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1616/9825/files/E100_20Heather_20side_20compressed_400.33x_691b63de-9e4d-437c-b94a-0415be8ddded.png?v=1703101615',
            },
            image_m: {
              src: 'https://cdn.shopify.com/s/files/1/1616/9825/files/E100_20Heather_20side_20compressed_400.33x_691b63de-9e4d-437c-b94a-0415be8ddded.png?v=1703101615',
            },
            textColor: 'text-black',
            heading: 'Headline',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            link: {
              text: 'Shop Now',
              url: '',
            },
            BtnStyle: 'button button--black',
          },
        ],
      },
      {
        label: 'Content Settings',
        name: 'content',
        component: 'group',
        description:
          'Dark overlay, content position, clickable image, hide button',
        fields: [
          {
            label: 'Content Align',
            name: 'contentAlign',
            component: 'select',
            options: [
              { label: 'Left', value: 'text-left' },
              { label: 'Center', value: 'text-center' },
              { label: 'Right', value: 'text-right' },
            ],
          },
          {
            label: 'Enable Content Overlay',
            name: 'contentOverlay',
            component: 'toggle',
            description: 'Image overlay with text',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Dark Overlay',
            name: 'darkOverlay',
            component: 'toggle',
            description: 'Adds 20% opacity black overlay over media',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Clickable Image',
            name: 'clickableImage',
            component: 'toggle',
            description: 'Makes entire image a clickable link',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Hide Button',
            name: 'hideButton',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          darkOverlay: false,
          clickableImage: true,
          hideButton: false,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Image aspect ratio, full width',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
            defaultValue: 50,
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
            defaultValue: 100,
          },
        ],
        defaultValue: {
          fullWidth: false,
          mobilePadding: 50,
          desktopPadding: 100,
        },
      },
    ],
  };
}
