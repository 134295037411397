const BGCOLORS = [
  { label: 'Black', value: 'var(--black)' },
  { label: 'Loden', value: 'var(--loden)' },
  { label: 'Dark Loden', value: 'var(--dark-loden)' },
  { label: 'Orion', value: 'var(--orion)' },
  { label: 'Dark Orion', value: 'var(--dark-orion)' },
  { label: 'Light Desert', value: 'var(--light-desert)' },
  { label: 'Medium Desert', value: 'var(--med-desert)' },
  { label: 'Dark Desert', value: 'var(--dark-desert)' },
  { label: 'Lava', value: 'var(--lava)' },
  { label: 'Dark Lava', value: 'var(--dark-lava)' },
  { label: 'White', value: 'var(--white)' },
];

export default {
  label: 'Product',
  name: 'product',
  component: 'group',
  description: 'Add to cart, back in stock, badges, color swatches, reviews',
  fields: [
    {
      label: 'Add To Cart',
      name: 'addToCart',
      component: 'group',
      description: 'Add to cart, sold out, presale text, subtext',
      fields: [
        {
          label: 'Add To Cart Text',
          name: 'addToCartText',
          component: 'text',
        },
        {
          label: 'Sticky Add To Cart Text',
          name: 'stickyAddToCartText',
          component: 'text',
        },
        {
          label: 'Sold Out Text',
          name: 'soldOutText',
          component: 'text',
        },
        {
          label: 'Preorder Text',
          name: 'preorderText',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'text',
          description: 'Text below the Add To Cart button',
        },
      ],
      defaultValue: {
        addToCartText: 'Add To Cart',
        stickyAddToCartText: 'Select Options',
        soldOutText: 'Sold Out',
        preorderText: 'Preorder',
        subtext: '',
      },
    },
    {
      label: 'Feature Icons/tags',
      name: 'featureIcons',
      component: 'group',
      description: 'Color swatches',
      fields: [
        {
          label: 'Features',
          name: 'features',
          component: 'group-list',
          itemProps: {
            label: '{{item.title}}',
          },
          defaultItem: () => ({
            name: 'New Feature',
          }),
          fields: [
            {
              label: 'Product Tag',
              name: 'tag',
              component: 'text',
            },
            {
              label: 'comparison type',
              name: 'comparisonType',
              component: 'select',
              options: [
                { label: 'Equal', value: 'equal' },
                { label: 'Start With', value: '' },
              ],
              defaultValue: '',
            },
            {
              label: 'Title',
              name: 'title',
              component: 'html',
            },
            {
              name: 'icon',
              label: 'Icon',
              component: 'image',
              description: 'Recommended Image Size: 40px x 40px',
            },
            {
              label: 'Icon Style',
              name: 'iconStyle',
              component: 'select',
              options: [
                { label: 'Natural', value: '' },
                { label: 'Circle', value: 'circle' },
              ],
              defaultValue: 'circle',
            },
          ],
        },
      ],
    },
    {
      label: 'Back In Stock',
      name: 'backInStock',
      component: 'group',
      description: 'Enable notifications, notify me text, modal texts',
      fields: [
        {
          label: 'Enable Notifications',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Notify Me Text',
          name: 'notifyMeText',
          component: 'text',
        },
        {
          label: 'Modal Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Modal Subtext',
          name: 'subtext',
          component: 'text',
        },
        {
          label: 'Modal Submit Text',
          name: 'submitText',
          component: 'text',
        },
      ],
      defaultValue: {
        enabled: false,
        notifyMeText: 'Notify Me When Available',
        heading: 'Notify Me When Available',
        subtext: `Enter your email below and we'll notify you when this product is back in stock.`,
        submitText: 'Submit',
      },
    },
    {
      label: 'Badges',
      name: 'badges',
      component: 'group',
      description: 'Custom Badges',
      fields: [
        {
          label: 'Badge',
          name: 'badgeList',
          component: 'group-list',
          itemProps: {
            label: '{{item.label}}',
          },
          defaultItem: () => ({
            label: 'New Badge',
          }),
          fields: [
            {
              label: 'Label',
              name: 'label',
              component: 'text',
              description: 'Max 14 characters',
            },
            {
              label: 'Product Tag',
              name: 'tag',
              component: 'text',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'select',
              options: BGCOLORS,
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'select',
              options: [
                { label: 'Black', value: 'var(--black)' },
                { label: 'White', value: 'var(--white)' },
              ],
            },
            {
              label: 'Border Color',
              name: 'borderColor',
              component: 'select',
              options: [
                { label: 'Black', value: 'var(--black)' },
                { label: 'Lava', value: 'var(--lava)' },
                { label: 'Background Color', value: '' },
              ],
            },
          ],
          defaultValue: [
            {
              label: 'sale',
              tag: 'badge::sale',
              bgColor: 'var(--lava)',
              textColor: 'var(--white)',
              borderColor: 'var(--lava)',
            },
            {
              label: 'final sale',
              tag: 'badge::final sale',
              bgColor: 'var(--white)',
              textColor: 'var(--black)',
              borderColor: 'var(--black)',
            },
            {
              label: 'new',
              tag: 'badge::new',
              bgColor: 'var(--white)',
              textColor: 'var(--black)',
              borderColor: 'var(--black)',
            },
            {
              label: 'best seller',
              tag: 'badge::best seller',
              bgColor: 'var(--white)',
              textColor: 'var(--black)',
              borderColor: 'var(--black)',
            },
          ],
        },
      ],
    },
    {
      label: 'Colors',
      name: 'colors',
      component: 'group',
      description: 'Color swatches',
      fields: [
        {
          label: 'Color Swatches',
          name: 'swatches',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          defaultItem: () => ({
            name: 'New Color',
          }),
          fields: [
            {
              label: 'Color Name',
              name: 'name',
              component: 'text',
            },
            {
              label: 'Color',
              name: 'color',
              component: 'color',
            },
            {
              name: 'image',
              label: 'Image',
              component: 'image',
              description:
                'If provided, image will overlay the color.\nEnsure image is no more than 2KB in size.\nRecommended Image Size: 50px x 50px',
            },
            {
              label: 'Image Alt',
              name: 'imageAlt',
              component: 'text',
              description:
                'Brief description of image. Default value is Color name',
            },
          ],
          defaultValue: [
            {
              name: 'Black',
              color: '#191919',
            },
            {
              name: 'White',
              color: '#FFFFFF',
            },
          ],
        },
      ],
    },
    {
      label: 'Reviews',
      name: 'reviews',
      component: 'group',
      description: 'Star rating',
      fields: [
        {
          label: 'Enable Star Rating',
          name: 'enabledStarRating',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabledStarRating: true,
      },
    },
    {
      label: 'Shipping & Returns',
      name: 'shippingReturns',
      component: 'group',
      description: 'Shipping & Returns',
      fields: [
        {
          label: 'Heading',
          name: 'headingText',
          component: 'text',
          defaultValue: 'Shipping & Returns',
        },
        {
          label: 'Shipping & Returns',
          name: 'subtext',
          component: 'markdown',
          defaultValue:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
      ],
      defaultValue: {
        headingText: 'Shipping & Returns',
        subtext:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
    },
    {
      label: 'Free Shipping Message',
      name: 'freeShippingMessage',
      component: 'group',
      description: 'PDP Buy Box Free Shipping Message & Tooltip',
      fields: [
        {
          label: 'Free Shipping Message',
          name: 'message',
          component: 'text',
        },
        {
          label: 'Free Shipping Tooltip',
          name: 'tooltip',
          component: 'group',
          description: 'Free Shipping Tooltip - Title, Description, CTA',
          fields: [
            {
              label: 'Tooltip Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Tooltip Description',
              name: 'description',
              component: 'textarea',
            },
            {
              label: 'Tooltip CTA',
              name: 'cta',
              component: 'link',
            },
          ],
        },
      ],
      defaultValue: {
        message: 'Free Shipping on Orders 75+',
        tooltip: {
          title: 'Free Shipping on Orders $75+',
          description:
            'For all domestic orders with a total of $75+. Does not apply to custom orders or international orders.',
          cta: {
            text: 'Read more',
            url: '',
          },
        },
      },
    },
    {
      label: 'Get it by message',
      name: 'getItByMessage',
      component: 'group',
      description: 'PDP Buy Box Free Shipping Message & Tooltip',
      fields: [
        {
          label: 'Get it by message',
          name: 'message',
          component: 'text',
        },
        {
          name: 'image',
          label: 'Image',
          component: 'image',
          description: 'Recommended Image Size: 68px x 68px',
        },
        {
          label: 'Image Alt',
          name: 'imageAlt',
          component: 'text',
          description:
            'Brief description of image. Default value is Color name',
        },
        {
          label: 'Modal Content',
          name: 'modalContent',
          component: 'group',
          description: 'Modal Content - Title, Description',
          fields: [
            {
              label: 'Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Modal Content',
              name: 'content',
              component: 'markdown',
              defaultValue: `**Lorem ipsum dolor sit amet**, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea _commodo consequat_.`,
            },
          ],
        },
      ],
    },
  ],
};
