import Script from 'next/script';
import Head from 'next/head';

export function GorgiasChat() {
  return (
    <>
      <Head>
        <link rel="preconnect" href="https://config.gorgias.chat" />
      </Head>
      <Script
        id="gorgias-chat-widget-install-v3"
        src="https://config.gorgias.chat/bundle-loader/01J5495DQ3P8XPZHST1WY497S7"
      />
    </>
  );
}
