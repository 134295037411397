import { useEffect, useState } from 'react';
import { Schema } from './GorgiasHelpCenter.schema';

export function GorgiasHelpCenter({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { gorgiasHelpCenterUid, section } = cms;
  const [loaded, setLoaded] = useState(false);
  const [gorgiasInjected, setGorgiasInjected] = useState(false);

  useEffect(() => {
    const t = document.getElementById(
      `gorgias-help-center-loader-${gorgiasHelpCenterUid}`
    );
    t && t.parentNode.removeChild(t);
    setLoaded(true);
    return () => {
      setLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && loaded && !gorgiasInjected) {
      const js = window.document.createElement('script');
      js.src = 'https://help-center.gorgias.help/api/help-centers/loader.js?v=2';
      js.type = 'text/javascript';
      js.fetchPriority = 'high';
      js.id = `gorgias-help-center-loader-${gorgiasHelpCenterUid}`;
      js.setAttribute('data-gorgias-help-center-uid', gorgiasHelpCenterUid);
      js.setAttribute('data-gorgias-loader-help-center', true);
      const wrapper = document.querySelector(
        `#${moduleID} .gorgias-help-center-${gorgiasHelpCenterUid}-wrapper`
      );
      if (wrapper) {
        wrapper.appendChild(js);
        setGorgiasInjected(true);
      }
    }
    return () => {
      const t = document.getElementById(
        `gorgias-help-center-loader-${gorgiasHelpCenterUid}`
      );
      t && t.parentNode.removeChild(t);
      setGorgiasInjected(false);
    };
  }, [loaded]);

  return (
    <div id={moduleID} className="md-py-contained md-px-contained">
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>

      {gorgiasHelpCenterUid && (
        <div
          className={`gorgias-help-center-${gorgiasHelpCenterUid}-wrapper`}
        />
      )}
    </div>
  );
}

GorgiasHelpCenter.displayName = 'Gorgias Help Center';
GorgiasHelpCenter.Schema = Schema;
